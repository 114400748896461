import _flatten from 'lodash/flatten';
import _uniqBy from 'lodash/uniqBy';
import {
  AnnotatedAsset,
  AnnotationTask,
  Label,
  ReviewTask,
  PredictionTask,
  LabelGroup
} from '@/types';
import api from '@/api';
import { ref, Ref } from '@vue/composition-api';
import {
  useAnnotationForAsset,
  useGenericAnnotationForAsset,
  useReviewTaskAnnotationForAsset
} from '@/api/use';

export const TaskKindEnum = {
  AnnotationTask: 'AnnotationTask',
  ReviewTask: 'ReviewTask',
  PredictionTask: 'PredictionTask'
} as const;

/**
 * type TaskKind = "AnnotationTask" | "ReviewTask" | "PredictionTask"
 */
export type TaskKind = keyof typeof TaskKindEnum;

export type TaskAndKind =
  | {
      kind: 'AnnotationTask';
      task: AnnotationTask;
    }
  | {
      kind: 'ReviewTask';
      task: ReviewTask;
    }
  | {
      kind: 'PredictionTask';
      task: PredictionTask;
    };

export interface AnnotationLayer<K extends TaskKind> {
  kind: K;
  task: TaskType<K>;
  loadAnnotations(): Promise<AnnotatedAsset[]>;
  loadAnnotationForAsset(assetId: string): Promise<AnnotatedAsset | undefined>;
  createAnnotationForAsset({
    assetId,
    groups,
    labels,
    duration
  }: {
    assetId: string;
    groups?: LabelGroup[];
    labels: Label[];
    duration: string;
  }): Promise<AnnotatedAsset>;
  useAnnotation(
    assetId: Ref<string>
  ): {
    mutate: () => Promise<void>;
    loading: Ref<boolean>;
    error: Ref<Error | null>;
    annotation: Ref<AnnotatedAsset | null>;
    loadedForAssetId: Ref<string>;
  };
}

export type TaskType<K extends TaskKind> = K extends 'AnnotationTask'
  ? AnnotationTask
  : K extends 'ReviewTask'
  ? ReviewTask
  : K extends 'PredictionTask'
  ? PredictionTask
  : never;

export type GenericTaskObject = AnnotationTask | ReviewTask | PredictionTask;

export class PredictionTaskLayer implements AnnotationLayer<'PredictionTask'> {
  readonly kind = 'PredictionTask';
  readonly task: PredictionTask;
  readonly annotationTask: AnnotationTask;
  // For now, we fetch pre-annotations via the AnnotationTask through /tasks/:taskId/pre-annotations
  constructor({
    task,
    annotationTask
  }: {
    task: PredictionTask;
    annotationTask: AnnotationTask;
  }) {
    this.task = task;
    this.annotationTask = annotationTask;
  }

  private get taskId(): string {
    return this.task.id;
  }

  loadAnnotations(): Promise<AnnotatedAsset[]> {
    return api.tasks.getPreAnnotations({ taskId: this.annotationTask.id });
  }

  async loadAnnotationForAsset(
    assetId: string
  ): Promise<AnnotatedAsset | undefined> {
    console.log(
      `Fetching annotations for task: ${this.taskId} and asset ID: ${assetId}`
    );
    const annotation = await api.tasks.getPreAnnotationForAsset({
      taskId: this.annotationTask.id,
      assetId
    });

    console.debug(
      `PredictionTask layer got pre-annotation for asset`,
      assetId,
      annotation
    );

    return annotation;
  }

  createAnnotationForAsset({
    assetId,
    labels,
    duration
  }: {
    assetId: string;
    labels: Label[];
    duration: string;
  }): Promise<AnnotatedAsset> {
    throw Error(`Cannot create annotation for prediction task`);
  }

  useAnnotation(assetId: Ref<string>) {
    const taskId = ref(this.task.id);
    const loadAnnotationFn = ({ assetId }: { assetId: string }) => {
      return this.loadAnnotationForAsset(assetId);
    };
    const loadAnnotation = ref(loadAnnotationFn);
    return useGenericAnnotationForAsset({ taskId, assetId, loadAnnotation });
  }
}

export class AnnotationTaskLayer implements AnnotationLayer<'AnnotationTask'> {
  readonly kind = 'AnnotationTask';
  readonly task: AnnotationTask;
  constructor({ task }: { task: AnnotationTask }) {
    this.task = task;
  }

  private get taskId(): string {
    return this.task.id;
  }

  loadAnnotations(): Promise<AnnotatedAsset[]> {
    return api.tasks.getAnnotations({ taskId: this.taskId });
  }

  async loadAnnotationForAsset(
    assetId: string
  ): Promise<AnnotatedAsset | undefined> {
    console.log(
      `Fetching annotations for task: ${this.taskId} and asset ID: ${assetId}`
    );
    const annotation = await api.tasks.getAnnotationForAsset({
      taskId: this.taskId,
      assetId
    });

    console.debug(
      `AnnotationTask layer got annotation for asset`,
      assetId,
      annotation
    );

    return annotation;
  }

  createAnnotationForAsset({
    assetId,
    groups,
    labels,
    duration
  }: {
    assetId: string;
    groups?: LabelGroup[];
    labels: Label[];
    duration: string;
  }) {
    return api.tasks.postAnnotationsForAsset({
      taskId: this.taskId,
      assetId,
      groups,
      labels,
      duration
    });
  }

  useAnnotation(assetId: Ref<string>) {
    const taskId = ref(this.task.id);
    return useAnnotationForAsset({ taskId, assetId });
  }
}

export class ReviewTaskLayer implements AnnotationLayer<'ReviewTask'> {
  readonly kind = 'ReviewTask';
  readonly task: ReviewTask;
  constructor({ task }: { task: ReviewTask }) {
    this.task = task;
  }

  private get taskId(): string {
    return this.task.id;
  }

  loadAnnotations(): Promise<AnnotatedAsset[]> {
    return api.reviewTasks.getAnnotations({ taskId: this.taskId });
  }

  loadAnnotationForAsset(assetId: string): Promise<AnnotatedAsset | undefined> {
    return api.reviewTasks.getAnnotationForAsset({
      taskId: this.taskId,
      assetId
    });
  }

  createAnnotationForAsset({
    assetId,
    groups,
    labels,
    duration
  }: {
    assetId: string;
    groups: LabelGroup[];
    labels: Label[];
    duration: string;
  }) {
    console.log(`Saving annotation for review task`, assetId, labels);
    return api.reviewTasks.postAnnotationsForAsset({
      taskId: this.taskId,
      assetId,
      groups,
      labels,
      duration
    });
  }

  useAnnotation(assetId: Ref<string>) {
    const taskId = ref(this.task.id);
    return useReviewTaskAnnotationForAsset({ taskId, assetId });
  }
}

export class LayersContainer {
  public readonly layers: AnnotationLayer<TaskKind>[];
  constructor({ layers }: { layers: AnnotationLayer<TaskKind>[] }) {
    this.layers = layers;
  }

  public get nLayers() {
    return this.layers.length;
  }

  public getLayer(index: number) {
    if (index < 0 || index >= this.nLayers) {
      throw Error(
        `Invalid layer index: ${index}, expected index in range [0, ${this
          .nLayers - 1}]`
      );
    }
    return this.layers[index];
  }

  public get activeLayer() {
    return this.layers[this.layers.length - 1];
  }

  public get activeTask() {
    return this.activeLayer.task;
  }

  public get activeTaskAndKind(): TaskAndKind {
    return {
      kind: this.activeLayer.kind,
      task: this.activeLayer.task
    } as TaskAndKind;
  }

  public get predictionLayer() {
    if (this.layers.length < 2) {
      return undefined;
    }
    return this.layers[this.layers.length - 2];
  }

  public usePrediction(assetId: Ref<string>) {
    const layer = this.predictionLayer;

    if (!layer) {
      // Resolve to fixed
      return {
        mutate: () => Promise.resolve(),
        loading: ref(false),
        error: ref(null as Error | null),
        annotation: ref(null as AnnotatedAsset | null),
        loadedForAssetId: assetId
      };
    }

    return layer.useAnnotation(assetId);
  }

  public useAnnotation(assetId: Ref<string>) {
    const layer = this.activeLayer;

    return layer.useAnnotation(assetId);
  }
}

export function makeLayer<K extends TaskKind>(
  kind: K,
  task: TaskType<K>
): AnnotationLayer<K> {
  if (kind === 'AnnotationTask') {
    // TODO Why doesn't this pass the type-check?
    // eslint-disable-next-line
    // @ts-ignore
    return new AnnotationTaskLayer({ task });
  } else if (kind === 'ReviewTask') {
    // eslint-disable-next-line
    // @ts-ignore
    return new ReviewTaskLayer({ task });
  }
  throw new Error(`Unknown task kind: ${kind}`);
}

export async function makeLayersFromAnnotationTask(
  taskId: string
): Promise<AnnotationLayer<TaskKind>[]> {
  const task = await api.tasks.getTask({ taskId });
  console.log(
    `Got annotation task, creating task layer from task ${task.id}`,
    task
  );
  const layer = makeLayer('AnnotationTask', task);

  const maybePredictionTaskId = task.prediction_task?.id;

  if (maybePredictionTaskId) {
    console.warn(
      `Prediction task found but loading predictions is not implemented yet`
    );

    const predictionTask = { id: maybePredictionTaskId };
    const predictionTaskLayer = new PredictionTaskLayer({
      task: predictionTask,
      annotationTask: task
    });
    return [predictionTaskLayer, layer];
  }

  return [layer];
}

export async function makeLayersFromReviewTask(
  taskId: string
): Promise<AnnotationLayer<TaskKind>[]> {
  console.log(`Fetching review task ${taskId}`);
  const reviewTask = await api.reviewTasks.getReviewTask({ taskId });
  const layer = makeLayer('ReviewTask', reviewTask);
  console.log(
    `Got review task, creating task layers from tasks ${reviewTask.tasks
      .map(task => task.id)
      .join(',')}`
  );
  const annotationTaskLayers = await Promise.all(
    reviewTask.tasks.map(({ id: taskId }) => {
      return makeLayersFromAnnotationTask(taskId);
    })
  );
  const layers = [..._flatten(annotationTaskLayers), layer];
  // Remove duplicates such as duplicated pre-annotation tasks
  return _uniqBy(layers, layer => layer.task.id);
}

export async function makeLayerContainerFromAnnotationTask(
  taskId: string
): Promise<LayersContainer> {
  const layers = await makeLayersFromAnnotationTask(taskId);
  return new LayersContainer({ layers });
}

export async function makeLayerContainerFromReviewTask(
  taskId: string
): Promise<LayersContainer> {
  const layers = await makeLayersFromReviewTask(taskId);
  return new LayersContainer({ layers });
}
