import { until } from '@vueuse/shared';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _fromPairs from 'lodash/fromPairs';
import _maxBy from 'lodash/maxBy';
import moment from 'moment';
import VueRouter from 'vue-router';

import AnnotationUis from '@/components/annotations/uis';
import {
  computed,
  reactive,
  ref,
  Ref,
  toRefs,
  watch
} from '@vue/composition-api';
import {
  TaskKind,
  makeLayerContainerFromReviewTask,
  makeLayerContainerFromAnnotationTask,
  LayersContainer,
  GenericTaskObject,
  TaskAndKind
} from '@/layers';
import { useFirstAssetInBatch } from '@/api/use';
import {
  AnnotatedAsset,
  AnnotationConfiguration,
  AnnotationGuideline,
  AnnotationProgressStatus,
  AnnotationUI as IAnnotationUI,
  Asset,
  Label,
  LabelGroup,
  RouteUpdateListener
} from '@/types';
import { AnnotationUI } from '@/components/annotations/ui';
import { Forbidden } from '@/api/client';

export const useAnnotationLayers = (
  taskKind: Ref<TaskKind>,
  taskId: Ref<string>
) => {
  const state = reactive({
    loading: false,
    error: null as Error | null,
    layers: null as LayersContainer | null
  });

  async function createLayers() {
    if (!taskId.value) {
      return;
    }
    state.loading = true;
    state.error = null;

    try {
      if (taskKind.value === 'ReviewTask') {
        console.log(`Creating layers from review task ${taskId.value}`);
        state.layers = await makeLayerContainerFromReviewTask(taskId.value);
        console.log(
          `Got ${state.layers.nLayers} layers: [${state.layers.layers
            .map(layer => `${layer.kind}: ${layer.task.id}`)
            .join(`, `)}]`
        );
      } else if (taskKind.value === 'AnnotationTask') {
        state.layers = await makeLayerContainerFromAnnotationTask(taskId.value);
      } else {
        state.error = new Error(`Unknown task kind: ${taskKind.value}`);
      }
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([taskKind, taskId], createLayers, { immediate: true });

  return { ...toRefs(state), mutate: createLayers };
};

/** The list of all annotations in the last active layer. */
export const useAnnotations = (layers: Ref<LayersContainer>) => {
  const state = reactive({
    loading: false,
    error: null as Error | null,
    annotations: [] as AnnotatedAsset[],
    loaded: false
  });

  async function fetchData() {
    state.loaded = false;

    if (!layers.value) {
      state.annotations = [];
      state.loaded = false;
      return;
    }

    if (layers.value.nLayers === 0) {
      state.annotations = [];
      state.loaded = true;
      return;
    }

    const lastLayer = layers.value.activeLayer;
    state.error = null;
    state.loading = true;

    try {
      state.annotations = await lastLayer.loadAnnotations();
      state.loaded = true;
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch(layers, fetchData, { immediate: true });

  return { ...toRefs(state) };
};

/** The list of all predictions, the annotations in the layer before the last. */
export const usePredictions = (layers: Ref<LayersContainer>) => {
  const state = reactive({
    loading: false,
    error: null as Error | null,
    predictions: [] as AnnotatedAsset[]
  });

  async function mutate() {
    const predictionLayer = layers.value?.predictionLayer;

    if (!predictionLayer) {
      state.predictions = [];
      return;
    }

    state.error = null;
    state.loading = true;
    try {
      state.predictions = await predictionLayer.loadAnnotations();
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch(layers, mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useRedirectToAssetIfRequired = ({
  assetId,
  batchId,
  setActiveAssetId,
  annotations,
  annotationsLoaded,
  assets
}: {
  assetId: Ref<string>;
  batchId: Ref<string>;
  annotations: Ref<AnnotatedAsset[]>;
  annotationsLoaded: Ref<boolean>;
  setActiveAssetId: (assetId: string | Ref<string>) => void;
  assets: Ref<Asset[]>;
}) => {
  console.debug(`Adding assetID redirect hook`);

  const { asset: firstAsset } = useFirstAssetInBatch({ batchId });

  async function checkRedirect() {
    console.debug(`Checking if should redirect...`);
    if (assetId.value) {
      console.debug(`Asset ID exists, skipping redirect`);
      return;
    }

    console.debug(`Waiting until assets loaded...`);
    await until(assets).toMatch(val => val.length > 0);

    console.debug(`Waiting for annotations to be loaded...`);
    await until(annotationsLoaded).toBe(true);

    const assetIdToAnnotated = _fromPairs(
      annotations.value.map(annotation => [annotation.asset.id, true])
    );

    let targetAsset = _find(
      assets.value,
      asset => !assetIdToAnnotated[asset.id]
    );

    if (!targetAsset) {
      console.debug(`Waiting for first asset to be loaded...`);
      await until(firstAsset).toBeTruthy();
      targetAsset = firstAsset.value;
    }

    // If there's still no asset ID defined, redirect
    if (!assetId.value) {
      console.log(`Redirecting to asset: ${targetAsset.id}`);
      setActiveAssetId(targetAsset.id);
    }
  }

  watch(assetId, checkRedirect, { immediate: true });
};

export const useActiveTask = (
  layers: Ref<LayersContainer>
): Ref<GenericTaskObject | undefined> => {
  return computed(() => {
    if (!layers.value || layers.value.nLayers === 0) {
      return undefined;
    }
    const task = layers.value.activeTask;
    return task;
  });
};

export const useActiveTaskAndKind = (
  layers: Ref<LayersContainer>
): Ref<TaskAndKind | undefined> => {
  return computed(() => {
    if (!layers.value || layers.value.nLayers === 0) {
      return undefined;
    }
    const taskAndKind = layers.value.activeTaskAndKind;
    return taskAndKind;
  });
};

export const useRedirectToUiIfRequired = ({
  batchId,
  uiName,
  task,
  router
}: {
  batchId: Ref<string>;
  uiName: Ref<string>;
  task: Ref<GenericTaskObject>;
  router: VueRouter;
}) => {
  if (uiName.value) {
    return;
  }
  const { asset: firstAsset } = useFirstAssetInBatch({ batchId });

  function redirectTo(uiName: string) {
    const route = router.currentRoute;
    if (route.query.ui !== uiName) {
      router.replace({
        ...route,
        query: {
          ...route.query,
          ui: uiName
        }
      });
    }
  }

  async function checkRedirect() {
    if (uiName.value) {
      return;
    }

    console.debug('Waiting for task to be loaded');
    await until(task).toBeTruthy();

    const uiNameFromTask = task.value.ui?.name;

    if (uiNameFromTask && !uiName.value) {
      console.log(`Redirecting to ui ${uiNameFromTask}`);
      redirectTo(uiNameFromTask);
      return;
    }

    console.debug('Waiting for first asset to be loaded to find a matching UI');
    await until(firstAsset).toBeTruthy();
    const targetUi = _find(AnnotationUis, (UI: AnnotationUI) =>
      UI.canLabelAsset(firstAsset.value)
    );

    if (targetUi && !uiName.value) {
      redirectTo(targetUi.name);
    }
  }

  watch(uiName, checkRedirect, { immediate: true });
};

export const useUiConfiguration = ({
  task,
  configurations,
  configurationsLoaded,
  errorLoadingConfigurations
}: {
  task: Ref<GenericTaskObject>;
  configurations: Ref<AnnotationConfiguration[]>;
  configurationsLoaded: Ref<boolean>;
  errorLoadingConfigurations: Ref<Error | null>;
}) => {
  const state = reactive({
    configuration: null as AnnotationConfiguration,
    loaded: false
  });

  watch(
    task,
    async function() {
      state.loaded = false;

      if (!task.value) {
        // Nothing to do yet
        state.configuration = null;
        state.loaded = false;
        return;
      }

      if (task.value?.configuration) {
        state.configuration = task.value.configuration;
        state.loaded = true;
        return;
      }

      // Task is loaded but no configuration was defined, pick the latest
      await until(configurationsLoaded).toBe(true);

      if (errorLoadingConfigurations.value instanceof Forbidden) {
        console.error(
          `Forbidden to load configurations for project, giving up resolving UI configuration`
        );
        state.configuration = null;
        state.loaded = true;
        return;
      }

      if (configurations.value?.length > 0) {
        // Pick the one with latest version
        state.configuration = _maxBy(configurations.value, 'version');
        console.log(
          `Picked configuration with version ${state.configuration.version}, from ${configurations.value.length} configurations available`
        );
        state.loaded = true;
        return;
      }
      state.configuration = null;
      state.loaded = true;
    },
    { immediate: true }
  );

  return { ...toRefs(state) };
};

export const useAnnotationUi = ({
  task,
  uiName,
  configurations,
  configurationsLoaded,
  errorLoadingConfigurations
}: {
  task: Ref<GenericTaskObject>;
  uiName: Ref<string>;
  configurations: Ref<AnnotationConfiguration[]>;
  configurationsLoaded: Ref<boolean>;
  errorLoadingConfigurations: Ref<Error | null>;
}) => {
  const state = reactive({
    ui: null as IAnnotationUI,
    loaded: false
  });

  watch(
    task,
    async function() {
      state.loaded = false;

      if (!task.value) {
        // Nothing to do yet
        state.ui = null;
        state.loaded = false;
        return;
      }

      const taskUi = task.value?.ui;

      if (taskUi.name !== uiName.value) {
        // Using some different UI than what the task was configured for, just give up
        state.ui = { name: uiName.value };
        state.loaded = true;
        return;
      }

      state.ui = taskUi;

      if (state.ui && state.ui.parameters != undefined) {
        // Parameters included in UI configuration, finish
        state.loaded = true;
        return;
      }

      // Back off to loading parameters from configurations stored in the project if they exist.
      // Backend should handle this in future, the below is done for backward compatibility.
      console.log(
        `Could not resolve UI parameters from task, reverting to using configurations stored in project`
      );
      await until(configurationsLoaded).toBe(true);

      if (errorLoadingConfigurations.value instanceof Forbidden) {
        console.error(
          `Forbidden to load configurations for project, giving up resolving UI parameters`
        );
        state.ui = { name: uiName.value };
        state.loaded = true;
        return;
      }

      if (configurations.value?.length > 0) {
        // Pick the one with latest version
        const configuration = _maxBy(configurations.value, 'version');
        state.ui = {
          name: configuration.ui.name,
          parameters: configuration.parameters
        };
        state.loaded = true;
        return;
      }
      state.ui = { name: uiName.value };
      state.loaded = true;
    },
    { immediate: true }
  );

  return { ...toRefs(state) };
};

export const useUiGuideline = (
  task: Ref<GenericTaskObject>,
  guidelines: Ref<AnnotationGuideline[]>
) => {
  const guideline = ref(null as AnnotationGuideline);
  watch(
    [task, guidelines],
    function() {
      if (task.value?.guideline) {
        guideline.value = task.value.guideline;
        return;
      }

      if (!task.value || !guidelines.value) {
        return;
      }

      if (guidelines.value.length > 0) {
        guideline.value = guidelines.value[guidelines.value.length - 1];
        return;
      }
      guideline.value = null;
    },
    { immediate: true }
  );

  return { uiGuideline: guideline };
};

export function useSaveLabels(
  layers: Ref<LayersContainer>,
  annotations: Ref<AnnotatedAsset[]>,
  startTime: Ref<number>,
  resetStartTime: () => void
) {
  /**
   * TODO **Replace this with O(1) operation.**
   *
   * Keep the list of annotations up-to-date by manually
   * mutating the array.
   * This is only done to keep the film-strip up-to-date.
   * Every annotation view is responsible fetching its up-to-date
   * annotation for each asset when the user navigates to that asset.
   */
  function updateAnnotations(
    annotations: Ref<AnnotatedAsset[]>,
    annotation: AnnotatedAsset
  ) {
    const prevIndex = _findIndex(
      annotations.value,
      ann => ann.asset.id === annotation.asset.id
    );
    if (prevIndex === -1) {
      annotations.value.push(annotation);
    } else {
      annotations.value.splice(prevIndex, 1, annotation);
    }
  }

  /**
   * Save labels to asset as annotation.
   * The function **does not handle errors** so the caller should
   * handle the management of error and loading state.
   */
  async function saveLabels({
    assetId,
    labels,
    groups
  }: {
    assetId: string;
    labels: Label[];
    groups?: LabelGroup[];
  }) {
    if (!layers.value || layers.value.nLayers === 0) {
      throw new Error(`Invalid state, layers not loaded yet`);
    }
    const layer = layers.value.activeLayer;

    const duration = moment
      .duration(new Date().getTime() - startTime.value)
      .toISOString();

    try {
      const annotatedAsset = await layer.createAnnotationForAsset({
        assetId,
        groups,
        labels,
        duration
      });

      updateAnnotations(annotations, annotatedAsset);
      resetStartTime();
      return annotatedAsset;
    } catch (err) {
      console.error(`Error saving labels`, err);
      throw err;
    }
  }

  return { saveLabels };
}

/** Use the annotation start time, measured from asset change or
 * from latest "resetStartTime" call.
 */
export function useAnnotationStartTime(assetId: Ref<string>) {
  const startTime = ref(new Date().getTime());

  function resetStartTime() {
    startTime.value = new Date().getTime();
  }

  watch(assetId, resetStartTime, { immediate: true });

  return { startTime, resetStartTime };
}

export const useAnnotationProgressStatus = ({
  assets,
  annotations
}: {
  assets: Ref<Asset[]>;
  annotations: Ref<AnnotatedAsset[]>;
}) => {
  const status = computed(() => {
    const nAssets = assets.value.length;
    const nAnnotations = annotations.value.length;
    const progressInPercents =
      nAssets > 0 ? (nAnnotations * 100.0) / nAssets : 0.0;
    return {
      assets: nAssets,
      annotations: nAnnotations,
      progressInPercents
    } as AnnotationProgressStatus;
  });
  return status;
};

export function useRouteUpdates() {
  /**
   * Use for adding a hook on route updates.
   * For example, ask the user to save unsaved changes.
   */
  const routeUpdateListener = ref(null as RouteUpdateListener);

  function registerRouteUpdateListener(func: RouteUpdateListener): void {
    console.debug(`Route update: Registering route update listener`);
    routeUpdateListener.value = func;
  }

  async function onRouteUpdate(): Promise<boolean> {
    if (routeUpdateListener.value) {
      console.debug(`Route update: listener registered, calling`);
      return routeUpdateListener.value();
    }
    console.debug(`Route update: listener not registered, proceeding`);
    return true;
  }

  return {
    registerRouteUpdateListener,
    onRouteUpdate
  };
}
